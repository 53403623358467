@font-face {
  font-family: '';
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  background: aliceblue;
}

.content-container {
  margin-top: 70px;
  margin-bottom: 20px;
}

.installment-preview-container {
  margin: 15px 5px;
  padding: 20px;
}

.debtor-form-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-container {
  padding: 60px 0;
  margin-top: 25px;
}

.login-container form {
  margin: 0 auto;
}

.print-container {
  width: 100%;
  margin: 5px 10px;
}

.print-table {
  width: 800px;
  font-size: 10px;
  border-collapse: collapse;
}

.print-cell {
  border: 1px black solid;
  border-collapse: collapse;
  padding: 2px;
}

.info-box {
  text-align: left;
  margin-bottom: 5px;
}

.info-box p {
  font-size: smaller;
  vertical-align: text-bottom;
}

.tools-box {
  padding: 5px 5px 5px 10px;
}

.form-input-field {
  padding: 5px;
  margin: 10px 20px;
}

.search-icon-button {
  margin-right: 50px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: auto;
  }

  .page-break-after {
    page-break-after: auto;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #1976d2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1976d2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
